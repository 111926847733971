import React from 'react';
import { Box, Button, TextNameonChat } from 'components/atomsKit/Elements';

import classes from './GiftCard.module.scss';

interface IProps {
    img: string;
    name: string;
    price: number;
    oldPrice: number;
    newPrice: number;
    discount: number;
    isActiveDiscount: boolean;
    isInCart?: boolean;
    handleAddCart?: any;
}

const GiftCard = ({
    img,
    name,
    price,
    isInCart,
    handleAddCart,
    oldPrice,
    isActiveDiscount,
    discount,
    newPrice,
}: IProps) => {
    return (
        <Box
            className={`${classes.wrap} ${isActiveDiscount && !!discount ? classes.gift_discount : ''}`}
        >
            <img
                className={classes.gift_image}
                alt={name}
                width="200"
                height="200"
                src={img}
                loading="lazy"
            />
            <TextNameonChat
                className={classes.gift_name}
                text={name}
                textAlign={'center'}
                margin="8px 0"
            />
            {isActiveDiscount && !!discount && (
                <p className={classes.gift_discount_text}>{`- ${+discount}%`}</p>
            )}
            <Box>
                {isActiveDiscount && !!discount && +oldPrice && (
                    <p className={classes.old_price}>{`${+oldPrice} credits`}</p>
                )}
                <p
                    className={classes.price}
                >{`${isActiveDiscount && !!discount && !!newPrice ? +newPrice : +price} credits`}</p>
                <Button
                    onClick={handleAddCart}
                    className={classes.addCart}
                    disabled={isInCart}
                    text={isInCart ? 'Added to cart' : 'Add to cart'}
                    iconSvg={isInCart ? 'check-circle-darkblue' : 'cart-white'}
                />
            </Box>
        </Box>
    );
};

export default GiftCard;
