import React from 'react';
import {
    Box,
    PageWrap,
    InfoGirl,
    InputModern,
    Button,
    TextNameonChat,
    Textonchat,
    Image,
    TextTimeonchat,
} from 'components/atomsKit/Elements';

import useCartPage from './useCartPage';

import classes from './CartPage.module.scss';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/auth/selectors';

const CartPage = () => {
    const {
        cart,
        handeDelOption,
        message,
        onChangeMessage,
        specialText,
        onChangeSpecialText,
        handleSendGift,
        profile,
    } = useCartPage();
    const isActiveDiscount = useSelector(selectUser)?.giftsDiscount;

    return (
        <PageWrap height100>
            <div className={classes.wrap}>
                <div className={classes.list}>
                    <TextNameonChat text="Cart" size={22} textAlign={'center'} margin="24px 0" />
                    <div className={classes.card}>
                        {cart.map((gift) => (
                            <div className={classes.option} key={gift.id}>
                                <button onClick={() => handeDelOption(gift.id)}>
                                    <Image name="cross-circle-red" className={classes.del} />
                                </button>
                                <Box
                                    flex
                                    align="center"
                                    className={`${+gift.discount && isActiveDiscount ? classes.card_discount : ''}`}
                                >
                                    <img
                                        width="100"
                                        height="100"
                                        alt=""
                                        src={gift.image}
                                        loading="lazy"
                                    />
                                    <TextNameonChat text={gift.name} margin="0 0 0 14px" />
                                    {isActiveDiscount && +gift.discount && (
                                        <p
                                            className={classes.gift_discount_text}
                                        >{`- ${gift.discount}%`}</p>
                                    )}
                                </Box>
                                <Textonchat text={gift.description} />
                                <Box flex marginTop="14px" className={classes.price_row}>
                                    <Textonchat text="Price:" size={10} />
                                    <Textonchat
                                        text={`${isActiveDiscount && +gift.discount && +gift.new_price ? +gift.new_price : +gift.price} credits`}
                                        size={10}
                                        margin="0 0 0 4px"
                                        className={classes.price}
                                    />
                                    {isActiveDiscount && +gift.discount && +gift.old_price && (
                                        <Textonchat
                                            text={`${+gift.old_price} credits`}
                                            textAlign={'center'}
                                            className={classes.old_price}
                                            margin="0"
                                        />
                                    )}
                                </Box>
                            </div>
                        ))}

                        <Box className={classes.info}>
                            <TextNameonChat
                                text="Gift addresee details:"
                                size={20}
                                margin="24px 0"
                            />

                            <Box flex align="center">
                                <TextNameonChat text="Addressee:" size={18} margin="0 14px 0 0" />
                                {profile?.id && (
                                    <InfoGirl
                                        imgLink={profile?.avatar}
                                        public_external_id={profile?.public_external_id}
                                        name={profile?.name}
                                        age={profile?.age}
                                        country_icon={profile?.flag}
                                        city={profile?.city}
                                    />
                                )}
                            </Box>
                        </Box>
                        <TextNameonChat
                            text="Message for the member"
                            size={18}
                            margin="24px 0 0 0"
                        />
                        <InputModern
                            isTextArea
                            infoText={`${message.length}/300`}
                            value={message}
                            onChange={onChangeMessage}
                        />
                        <TextTimeonchat
                            text={
                                'The member will receive this gift with your printed message (maximum 300 symbols) on a greeting card'
                            }
                        />
                        <TextNameonChat
                            text="Special gift giving preferences"
                            size={18}
                            margin="24px 0 0 0"
                        />
                        <InputModern
                            isTextArea
                            infoText={`${specialText.length}/300`}
                            value={specialText}
                            onChange={onChangeSpecialText}
                        />
                        <TextNameonChat
                            text="Total gift price:"
                            size={18}
                            margin="16px 0 0 0"
                            textAlign={'center'}
                        />
                        <Box
                            flex
                            align="center"
                            justify="center"
                            className={classes.gift_total_price}
                        >
                            <TextNameonChat
                                text={`${cart.reduce(
                                    (accumulator, currentValue) =>
                                        accumulator +
                                        (isActiveDiscount &&
                                        +currentValue.discount &&
                                        +currentValue.new_price
                                            ? +currentValue.new_price
                                            : +currentValue.price),
                                    0,
                                )} credits`}
                                size={18}
                                className={classes.price}
                            />
                            {isActiveDiscount &&
                                cart.some((item) => item.discount && item.old_price) && (
                                    <TextNameonChat
                                        text={`${cart.reduce(
                                            (accumulator, currentValue) =>
                                                accumulator +
                                                (+currentValue.discount && +currentValue.old_price
                                                    ? +currentValue.old_price
                                                    : 0),
                                            0,
                                        )} credits`}
                                        className={classes.old_price}
                                        size={12}
                                    />
                                )}
                        </Box>
                        <Button
                            disabled={cart.length === 0}
                            onClick={handleSendGift}
                            text="Buy gifts"
                            icon="x1"
                            margin="10px 0 0 0"
                            className={classes.sendBtn}
                        />
                    </div>
                </div>
            </div>
        </PageWrap>
    );
};

export default CartPage;
