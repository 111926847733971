import React from 'react';
import { Fragment } from 'react';
import { Avatar, Button, SliderContent, Image } from 'components/atomsKit/Elements';

import { formatDateTime, objectToArray } from 'utils/common';

import cn from 'classnames';

import classes from './MailTextBox.module.scss';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/auth/selectors';

interface IProps {
    name: string;
    user_name: string;
    age: any;
    data: any;
    onChangeSetbookmark: any;
    onChangeTrashmail: any;
    handleContent?: any;
    handlePay?: any;
    price:
        | {
              [key: string]: number;
          }
        | undefined;
}

const MailTextBox = ({
    name,
    user_name,
    age,
    data,
    onChangeSetbookmark,
    onChangeTrashmail,
    handleContent,
    handlePay,
    price,
}: IProps) => {
    const userState = useSelector(selectUser)?.user_info;

    const mailText = data?.mail?.message_content || '';
    const isUserCard = data?.mail?.male_id === data?.mail?.sender_id;
    const isUserMail = +data?.mail?.operator === 0;
    const isNewMail = userState?.newMail;
    const isNewMailCategory1 = !!userState?.newMail && +userState?.newMailCategory === 1;

    const isHideText =
        !isUserMail &&
        isNewMail &&
        (isNewMailCategory1
            ? !+data?.mail?.paid_read
            : !+data?.mail?.paid_read || !!(+data?.mail?.paid_read && !+data?.mail?.read_status));

    const splitAndBlur = () => {
        if (!isHideText) return mailText;

        const visibleText = mailText.substr(0, 25);
        const unVisibleText = mailText.substr(25);

        return (
            <Fragment>
                {visibleText}
                <Fragment>
                    <span className={classes.blur}>{unVisibleText}</span>
                </Fragment>
            </Fragment>
        );
    };

    return (
        <div className={cn(classes.wrap, data?.mail?.read_status === 0 && classes.wrap_notRead)}>
            <div className={classes.header}>
                <div className={classes.avatart}>
                    <Avatar
                        small
                        imgLink={data?.mail?.sender_image}
                        user_name={isUserCard ? user_name : name}
                    />
                    <p className={classes.name}>{isUserCard ? 'You' : `${name}, ${age}`}</p>
                </div>
                {!isUserCard && (
                    <Button
                        text={data?.mail?.bookmark === 0 ? 'Bookmarks' : 'Bookmarked'}
                        iconSvg={
                            data?.mail?.bookmark === 0 ? 'c3l-bookmark-empty' : 'c3l-bookmark-full'
                        }
                        onClick={() => {
                            onChangeSetbookmark(
                                data?.mail?.id,
                                data?.mail?.male_id,
                                data?.mail?.bookmark,
                            );
                        }}
                        className={classes.btn}
                        isWhite
                        borderOff
                        noBg
                    />
                )}
            </div>
            <p className={classes.text}>{splitAndBlur()}</p>
            {isUserCard && (
                <Image
                    name={`${data?.mail?.read_status ? 'readed' : 'send'}`}
                    className={classes.readStatus}
                />
            )}
            <div className={classes.footer}>
                <p className={classes.time}>{formatDateTime(data?.mail?.date_created)}</p>
                <Button
                    onClick={() => {
                        onChangeTrashmail(data?.mail?.id, data?.mail?.male_id, isUserCard);
                    }}
                    isWhite
                    noBg
                    borderOff
                    icon={'been'}
                    text="Delete"
                    color={'#3333334d'}
                    className={classes.del}
                />
            </div>
            {Object.values(data.attachments).length > 0 && (
                <div>
                    <div className={classes.line} />
                    {!objectToArray(data.attachments)?.every(
                        (item: any) => +item?.attachment_free,
                    ) && (
                        <p className={classes.costContentText}>
                            {`The cost of each attachment is: photo - ${price?.GET_IMAGE_MAIL}
              credits, video - ${price?.GET_VIDEO_MAIL_NEW} credits, audio -
              ${price?.GET_AUDIO_NEW} credits. Special bonus - the first photo is
              free.`}
                        </p>
                    )}

                    <SliderContent
                        handleContent={handleContent}
                        girlsList={Object.values(data.attachments)}
                        chat_id={data?.mail?.chat_id}
                        id={data?.mail?.id}
                    />
                </div>
            )}
            {isHideText && (
                <button onClick={() => handlePay(data?.mail?.id)} className={classes.payBtn}>
                    Read for 10 credits
                </button>
            )}
        </div>
    );
};

export default MailTextBox;
