import React from 'react';
import { Image } from 'components/atomsKit/Elements';

import classes from './PanelWithButton.module.scss';

interface IProps {
    buttonConfig: {
        name: string;
        isActiveDiscount: boolean;
        discountSize: string;
        onClick: any;
    }[];
}

const PanelWithButton = ({ buttonConfig }: IProps) => {
    return (
        <div className={classes.wrap}>
            {buttonConfig.map(
                (item: {
                    name: string;
                    isActiveDiscount: boolean;
                    discountSize: string;
                    onClick: any;
                }) => (
                    <button onClick={item.onClick} className={classes.btn} key={item.name}>
                        <Image name="c3-opt-gift" className={classes.icon} />
                        {item.name}
                        {item.isActiveDiscount && (
                            <div className={classes.btn_label}>-{item.discountSize}</div>
                        )}
                    </button>
                ),
            )}
        </div>
    );
};

export default PanelWithButton;
