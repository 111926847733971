import React from 'react';
import Select, { components } from 'react-select';
import cn from 'classnames';
import { DropdownIndicatorPart } from './Parts/Parts';
import { selectStyles } from './Parts/selectStyles';
import classes from './SelectDropDown.module.scss';
import PropTypes from 'prop-types';

const SelectDropDown = ({
    placeholder,
    options,
    label = '',
    value,
    onInputChange = () => {},
    inputValue = '',
    onChange,
    className = {},
    isSearchable = false,
    isClearable = false,
    error = false,
    isMulti = false,
    menuPlacement = 'bottom',
    formatOptionLabel = '',
    offDropDownContainer = false,
    defaultLabel = '',
}) => {
    const SingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            <span>
                {formatOptionLabel} {children}
            </span>
        </components.SingleValue>
    );

    SingleValue.propTypes = {
        children: PropTypes.node,
    };

    const IndicatorsContainer = ({ children, ...props }) => (
        <components.IndicatorsContainer {...props}>
            <span>{children}</span>
        </components.IndicatorsContainer>
    );

    IndicatorsContainer.propTypes = {
        children: PropTypes.node,
    };

    return (
        <div className={cn(classes.wrapSelect, className)}>
            {label && <h2 className={cn(classes.title)}>{label}</h2>}

            <div className={classes.selectWrap}>
                <Select
                    onInputChange={onInputChange}
                    inputValue={inputValue || undefined}
                    isMulti={isMulti}
                    error={error}
                    value={
                        defaultLabel
                            ? value?.value?.toLowerCase() === 'not specified'
                                ? null
                                : value
                            : value
                    }
                    placeholder={defaultLabel || placeholder}
                    isSearchable={isSearchable}
                    isClearable={isClearable}
                    components={{
                        IndicatorSeparator: undefined,
                        SingleValue,
                        IndicatorsContainer: offDropDownContainer
                            ? DropdownIndicatorPart
                            : IndicatorsContainer,
                    }}
                    hideSelectedOptions={false}
                    onChange={onChange}
                    options={options}
                    styles={selectStyles}
                    tabSelectsValue={false}
                    menuPlacement={menuPlacement}
                />
            </div>
        </div>
    );
};

SelectDropDown.propTypes = {
    placeholder: PropTypes.string,
    options: PropTypes.array,
    label: PropTypes.string,
    value: PropTypes.any,
    onInputChange: PropTypes.func,
    inputValue: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.any,
    isSearchable: PropTypes.bool,
    isClearable: PropTypes.bool,
    error: PropTypes.bool,
    isMulti: PropTypes.bool,
    menuPlacement: PropTypes.string,
    formatOptionLabel: PropTypes.string,
    offDropDownContainer: PropTypes.bool,
    defaultLabel: PropTypes.string,
};

export default SelectDropDown;
