import { useState } from 'react';

import { postRequest } from 'utils/requests';

import { POLICY_PAGE_WITH_200, policyLinkConfig } from 'data/config';
import useHandleActiveBlocks from '../../../../hooks/useHandleActiveBlocks';

const usePolicyPage = () => {
    const [policyInfo, setPolicyInfo] = useState();
    const { isHideGift } = useHandleActiveBlocks();

    const changePolicy = (seo_url) => {
        const isPolicyPage200 = isHideGift && POLICY_PAGE_WITH_200.includes(seo_url);
        const pageName = isPolicyPage200 ? `${seo_url}-200` : seo_url;

        postRequest('/api/page/show', { seo_url: pageName }).then((res) => {
            setPolicyInfo(res.result);
        });
    };

    return {
        policyLinkConfig,
        changePolicy,
        policyInfo,
        setPolicyInfo,
    };
};

export default usePolicyPage;
