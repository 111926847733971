const prefix = process.env.REACT_APP_API_MIRROR !== 'Production' ? 'mstage.' : 'm.';
const payPrefix = process.env.REACT_APP_API_MIRROR !== 'Production' ? 'stage.' : '';

export const MIRROR = {
    sofiadate: {
        MIRROR: process.env.REACT_APP_API_MIRROR,
        URL: `${prefix}sofiadate.com`,
        PAY_URL: `${payPrefix}sofiadate.com`,
        NAME: 'Sofiadate',
        NAME_DOMAIN: 'Sofiadate.com',
        SUPPORT_MAIL: 'support@sofiadate.com',
        GOOGLE_ID: '539249899214-o2vvebvr6d29as2nk6ap087ln2698704.apps.googleusercontent.com',
        SITE_ID: 1,
        BRAND_URL: 'sofia20',
        COLOR_LOADER: '#00317B',
        LIGAL_TEXT: [
            'ASTRASOFT PROJECTS LTD',
            'Ifigeneias 14, 3036, Limassol, Cyprus',
            'Registration number HE 409810',
            '',
        ],
        SOCIAL_LIST: [
            { icon: 'fs-3', link: 'https://www.facebook.com/sofiadateblog/' },
            { icon: 'fs-5', link: 'https://www.instagram.com/sofia_date/' },
            { icon: 'fs-6', link: 'https://twitter.com/date_sofia' },
        ],
        LOGIN_BG_IMG: 'main-bg-mob.jpg',
        PRODUCT_VERSION: '0.1.1',
    },
    avodate: {
        MIRROR: process.env.REACT_APP_API_MIRROR,
        URL: `${prefix}avodate.com`,
        PAY_URL: `${payPrefix}avodate.com`,
        NAME: 'Avodate',
        NAME_DOMAIN: 'Avodate.com',
        SUPPORT_MAIL: 'support@avodate.com',
        GOOGLE_ID: '539249899214-cnt6a46a7mmj5h60c7cv78ruv2m8rvu6.apps.googleusercontent.com',
        SITE_ID: 8,
        BRAND_URL: 'avodate',
        COLOR_LOADER: '#289292',
        LIGAL_TEXT: [
            'SEMPITERNA DIGITAL LIMITED',
            'ELIAS VENEZI, 2A, ATHIENITIS STROVOLOS PARK, 4th Floor, Office 402, Strovolos, CY-01, 2042, CY',
            'Registration number HE 440404',
        ],
        SOCIAL_LIST: [
            { icon: 'fs-3', link: 'https://www.facebook.com/avodateblog/' },
            { icon: 'fs-5', link: 'https://instagram.com/avodate_blog' },
            { icon: 'fs-6', link: 'https://twitter.com/avo_date' },
        ],
        LOGIN_BG_IMG: 'main-bg-avodate.png',
        PRODUCT_VERSION: '0.1.1',
    },

    okamour: {
        MIRROR: process.env.REACT_APP_API_MIRROR,
        URL: `${prefix}okamour.com`,
        PAY_URL: `${payPrefix}okamour.com`,
        NAME: 'Okamour',
        NAME_DOMAIN: 'Okamour.com',
        SUPPORT_MAIL: 'support@okamour.com',
        GOOGLE_ID: '539249899214-ievhgh2eeqf2uuhh801m2jml1bv2dme8.apps.googleusercontent.com',
        SITE_ID: 7,
        BRAND_URL: 'okamour',
        COLOR_LOADER: '#474f5c',
        LIGAL_TEXT: [
            'SEMPITERNA DIGITAL LIMITED',
            'ELIAS VENEZI, 2A, ATHIENITIS STROVOLOS PARK, 4th Floor, Office 402, Strovolos, CY-01, 2042, CY',
            'Registration number HE 440404',
        ],
        SOCIAL_LIST: [
            { icon: 'fs-3', link: 'https://www.facebook.com/okamour/' },
            { icon: 'fs-5', link: 'https://instagram.com/okamour_' },
            { icon: 'fs-6', link: 'https://x.com/okamour_' },
        ],
        LOGIN_BG_IMG: 'main_bg_new_okamour.jpg',
        PRODUCT_VERSION: '0.1.1',
    },
};
