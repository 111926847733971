import React from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Stick, Avatar, SliderContent, Button, Counter, Box } from 'components/atomsKit/Elements';
import { objectToArray } from 'utils/common';
import cn from 'classnames';
import classes from './LetterCard.module.scss';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/auth/selectors';

interface IProps {
    cardInfo: any;
    attachments?: any;
    handleDel: (chat_id: number) => void;
    handleRestore: (chat_id: number) => void;
    handleContent?: any;
    price:
        | {
              [key: string]: number;
          }
        | undefined;
    filterChat: string;
}

const LetterCard = ({
    cardInfo,
    attachments = [],
    handleDel,
    handleContent,
    handleRestore,
    price,
    filterChat,
}: IProps) => {
    const userState = useSelector(selectUser)?.user_info;

    const mailText = cardInfo?.message_content || '';
    const mailUnreadCount = +cardInfo?.unread_count;
    const isUserCard = cardInfo?.male_id === cardInfo?.sender_id;
    const isUserMail = +cardInfo?.operator === 0;
    const isNewMail = userState?.newMail;
    const isNewMailCategory1 = !!userState?.newMail && +userState?.newMailCategory === 1;

    const isHideText =
        !isUserMail &&
        isNewMail &&
        (isNewMailCategory1
            ? !+cardInfo?.paid_read
            : !+cardInfo?.paid_read || !!(+cardInfo?.paid_read && !+cardInfo?.read_status));

    const isFreeReading =
        !isUserMail &&
        isNewMail &&
        !!mailUnreadCount &&
        (!+cardInfo?.connect || !!(+cardInfo?.paid_read && !+cardInfo?.read_status));

    const splitAndBlur = () => {
        if (!isHideText) return mailText;

        const visibleText = mailText.substr(0, 25);
        const unVisibleText = mailText.substr(25);

        return (
            <Fragment>
                {visibleText}
                <Fragment>
                    <span className={classes.blur}>{unVisibleText}</span>
                </Fragment>
            </Fragment>
        );
    };

    const navigate = useNavigate();

    return (
        <section className={classes.wrapLetters}>
            <div
                className={cn(
                    classes.wrapCard,
                    !cardInfo?.read_status && !isUserCard && classes.wrapCard_notReading,
                )}
            >
                <Link
                    to={`/letter?profileId=${cardInfo?.public_external_id}`}
                    className={classes.link}
                >
                    <div className={classes.wrapInfo}>
                        <div className={classes.wrpaInfoAvatar}>
                            {isUserCard ? <p className={classes.to}>To:</p> : <></>}
                            <Avatar
                                imgLink={
                                    isUserCard ? cardInfo?.recipient_image : cardInfo?.sender_image
                                }
                                isFavorite={!!cardInfo?.is_favorite}
                                isOnline={!!cardInfo?.sender_online}
                            />
                            <div className={classes.avatartTextInfo}>
                                <p className={classes.id}>ID: {cardInfo?.public_external_id}</p>
                                <p className={classes.name}>
                                    {isUserCard ? cardInfo?.recipient_name : cardInfo?.sender_name},{' '}
                                    {isUserCard ? cardInfo?.recipient_age : cardInfo?.sender_age}
                                </p>
                                <p className={classes.city}>
                                    <img
                                        src={
                                            isUserCard
                                                ? cardInfo?.recipient_country_icon
                                                : cardInfo?.sender_country_icon
                                        }
                                        alt=""
                                        width="15px"
                                        loading="lazy"
                                        className={classes.flag}
                                    />
                                    {isUserCard ? cardInfo?.recipient_city : cardInfo?.sender_city}
                                </p>
                            </div>
                        </div>
                        <Box flex>
                            {isFreeReading && (
                                <Stick text={'Free reading'} className={classes.freeReading} />
                            )}
                            {cardInfo?.unread_count > 0 ? (
                                <Counter counter={cardInfo?.unread_count} bigMode />
                            ) : (
                                <Stick
                                    className={cn(
                                        classes.stick,
                                        !cardInfo?.answered && classes.stick_error,
                                    )}
                                    text={cardInfo?.answered ? 'Answered' : 'Unansvered'}
                                    error={!cardInfo?.answered}
                                />
                            )}
                        </Box>
                    </div>
                    <p className={classes.text}>{splitAndBlur()}</p>
                </Link>
                {attachments.length > 0 && (
                    <>
                        <div className={classes.line} />
                        {!objectToArray(attachments)?.every(
                            (item: any) => +item?.attachment_free,
                        ) && (
                            <p className={classes.costContentText}>
                                {`The cost of each attachment is: photo - ${price?.GET_IMAGE_MAIL}
              credits, video - ${price?.GET_VIDEO_MAIL_NEW} credits, audio -
              ${price?.GET_AUDIO_NEW} credits. Special bonus - the first photo is
              free.`}
                            </p>
                        )}

                        <SliderContent
                            handleContent={handleContent}
                            girlsList={attachments}
                            chat_id={cardInfo?.chat_id}
                            id={cardInfo?.id}
                        />
                    </>
                )}
            </div>
            <div className={classes.btnsWrap}>
                {filterChat !== 'trash' ? (
                    <>
                        {!cardInfo?.answered && (
                            <Button
                                onClick={() =>
                                    navigate(`/letter?profileId=${cardInfo?.public_external_id}`)
                                }
                                text={'Reply'}
                                icon={'back'}
                                isWhite
                                borderOff
                                noBg
                                className={classes.btnPanel}
                            />
                        )}
                        <Button
                            onClick={() => navigate(`/profile-woman/${cardInfo?.female_id}`)}
                            text={'Profile'}
                            icon={'user'}
                            isWhite
                            borderOff
                            noBg
                            className={classes.btnPanel}
                        />
                        <Button
                            disabled={!cardInfo?.read_status && !isUserCard}
                            onClick={() => {
                                handleDel(cardInfo?.chat_id);
                            }}
                            text={'Delete'}
                            icon={'been'}
                            isWhite
                            borderOff
                            noBg
                            className={classes.btnPanel}
                        />
                    </>
                ) : (
                    <Button
                        disabled={!cardInfo?.read_status && !isUserCard}
                        onClick={() => {
                            handleRestore(cardInfo?.id);
                        }}
                        text={'Restore'}
                        iconSvg={'c3l-restore-msg'}
                        isWhite
                        bgImg
                        borderOff
                        noBg
                        className={classes.btnPanel}
                    />
                )}
            </div>
        </section>
    );
};

export default LetterCard;
